import * as React from "react"
import SiteLayout from "components/layouts/SiteLayout";
import {Typography} from "@mui/material";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import Section from "components/molecules/Section";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <SiteLayout>
      <Section padding={10}>
        <Typography variant="h1" align="center" color="primary">404</Typography>
        <Typography variant="h3" align="center">{t("pageNotFound")}</Typography>
      </Section>
    </SiteLayout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
